import { Mockup, MockupID, MockupParams } from "@/utils/mockupBuilder/types/MockupTypes"
import { GetMockupParams } from "@/utils/mockupBuilder/useGetMockupParams"
import { GenerateMockupParams } from "@/utils/mockupBuilder/useHandleMockupImages"
import { ImageData } from "@/utils/mockupBuilder/types/MockupTypes"
import { IdRefWidget, IdRefWidgetWithPaginationItem, PendingNotificationCreateWithoutId } from "deblank-api-types"
import { ColorSelectValue } from "@/components/WidgetsByType/Colors/WidgetColorsTypes"
import { FontSelectValue } from "@/components/WidgetsByType/Fonts/WidgetFontsTypes"
import { ImageSelectValue } from "@/components/WidgetsByType/Images/WidgetImagesTypes"

export enum ConfigStepType {
	select = "SELECT",
}

export enum SelectType {
	colors = "colors",
	fonts = "fonts",
	images = "images",
	custom = "custom",
	input = "input",
	imagesMultiSelect = "imagesMultiSelect",
}

export type CustomSelectValue = {
	value: string,
}

export type InputSelectValue = {
	value: string,
}


export type SelectValue = ColorSelectValue |
	FontSelectValue | ImageSelectValue | CustomSelectValue | InputSelectValue | ImageSelectValue[]

type SelectStep<T> = {
	componentType: ConfigStepType.select,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSelect: (context: RelatedActionContext<T>) => (data: any) => void,
	onGetValue: (context: RelatedActionContext<T>) => SelectValue,
} & (
		{
			selectType: SelectType.colors | SelectType.fonts | SelectType.images
			| SelectType.input | SelectType.imagesMultiSelect,
		}
		| {
			selectType: SelectType.custom,
			options: {
				value: string,
				label: string,
				thumbnailImage: string,
			}[],
		}

	)

export type RelatedActionConfigStep<T> = {
	required?: boolean,
	title: string,
	placeholder?: string,
} & (
		SelectStep<T>
	)

export type RelatedActionContext<T> = {
	idRef: IdRefWidget,
	currentValue: T,
	onAddPendingMessage: (params: {
		message: string,
		prompt?: string,
		idRefs?: IdRefWidgetWithPaginationItem[],
		generatedByWidget?: IdRefWidget,

	}) => void,

	onAddCreateNotifications: (params: {
		notifications: (Omit<PendingNotificationCreateWithoutId, "type"> & { generatedByWidget: IdRefWidget, })[],
	}) => void,

	onSetState: (set: (prevState: T) => T) => void,

	mockupBuilder: {
		getMockupParams: (params: GetMockupParams) => MockupParams,
		getMockups: (params: GetMockupParams) => Mockup<MockupParams>[],
		getMockupImages: (params: GenerateMockupParams<MockupParams>) => Promise<({
			imageData: ImageData,
			mockupId: MockupID,
		} | undefined)[] | undefined>,
	},
}

export type RelatedActionConfig<T> = {
	steps: RelatedActionConfigStep<T>[],
	isBeta?: boolean,
	defaultState: T,
	processAction: (context: RelatedActionContext<T>) => (data: T) => (Promise<void> | void),
}


export type RelatedActionData<T> = {
	id: string,
	label: string,
	config: RelatedActionConfig<T>,
}


export type OneStepAction<T1> = {
	step1: T1,
}

export type TwoStepAction<T1, T2> = {
	step1: T1,
	step2: T2,
}

export type ThreeStepAction<T1, T2, T3> = {
	step1: T1,
	step2: T2,
	step3: T3,
}
